import { Navigation, PageContent } from "@iss/client-react-components";
import React from "react";
import { Table } from "semantic-ui-react";
import downloaddata2 from "./images/downloaddata2.gif";
import downloaddata1 from "./wm_images/wealthmine_downloaddata.JPG";

export function DataDownloads() {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Data Downloads"></Navigation.PageHeader>
        <p className="line-height">
          An important functionality of WealthMINE is to serve as a query
          mechanism allowing users to select segments of the data to further
          integrate to other analysis beyond the scope of the WealthMINE
          Framework. To support users WealthMINE allows them to perform data
          downloads of the views they might be working on.
        </p>
        <p className="line-height">
          The image below indicates the location of the download button. When
          the user presses the system will download a fully comprehensive data
          structure relative to the current view. The format is comma delimited
          file (csv), and if excel is the default csv program it will open the
          data in excel automatically.
        </p>

        <div style={{ margin: "20px" }}>
          <img src={downloaddata1} style={{ height: "300pt" }}></img>
          <br />
        </div>
      </PageContent>
    </div>
  );
}

export function DataStructure(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Data Structure"></Navigation.PageHeader>

        <div style={{ margin: "20px" }}>
          <img src={downloaddata2} style={{ height: "300pt" }}></img>
          <br />
        </div>

        <Table className="help-custom-table">
          <Table.HeaderCell>Item</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <p>Context</p>
              </Table.Cell>
              <Table.Cell>
                Indicates the context of the data download based on the current
                context of the view. It may take the form of Canada, Province,
                CMA, or City
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Granularity</Table.Cell>
              <Table.Cell>
                Indicates the level of detail of the data from a location
                perspective. In some cases the context might be Canada and the
                granularity Province, but it can also be CMA, or City
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Sorting</Table.Cell>
              <Table.Cell>
                The sorting selected in the view will dictate the sorting of the
                data download.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Filter Refinement</Table.Cell>
              <Table.Cell>Details of the filter selected.</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Location</Table.Cell>
              <Table.Cell>
                Name of the location depending on the granularity.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Count / fCount</Table.Cell>
              <Table.Cell>
                Number of Households for the context and the filter
              </Table.Cell>
            </Table.Row>
            {/* <Table.Row>
              <Table.Cell>Count / fCount</Table.Cell>
              <Table.Cell>
                Number of Households for the context and the filter
              </Table.Cell>
            </Table.Row> */}
            <Table.Row>
              <Table.Cell>Wealth / fWealth</Table.Cell>
              <Table.Cell>
                Financial wealth for the context and the filter
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>AGE#h / fAGE#h</Table.Cell>
              <Table.Cell>
                Number of households for each of the seven
                <span
                  className="clickable"
                  onClick={() => {
                    props.handleChange("Age");
                  }}
                >
                  Age
                </span>
                segments for the context and the filter
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>AGE#w / fAGE#w</Table.Cell>
              <Table.Cell>
                Financial wealth for each of the seven
                <span
                  className="clickable"
                  onClick={() => {
                    props.handleChange("Age");
                  }}
                >
                  Age
                </span>
                segments for the context and the filter
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>INC#h / fINC#h</Table.Cell>
              <Table.Cell>
                Number of households for each of the seven
                <span
                  className="clickable"
                  onClick={() => {
                    props.handleChange("Income");
                  }}
                >
                  Income
                </span>
                segments for the context and the filter
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>INC#w / fINC#w</Table.Cell>
              <Table.Cell>
                Financial wealth for each of the seven
                <span
                  className="clickable"
                  onClick={() => {
                    props.handleChange("Income");
                  }}
                >
                  Income
                </span>
                segments for the context and the filter
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>CFA#h / fCFA#h</Table.Cell>
              <Table.Cell>
                Number of households for each of the seven
                <span
                  className="clickable"
                  onClick={() => {
                    props.handleChange("FinancialWealth");
                  }}
                >
                  Financial Wealth
                </span>
                segments for the context and the filter
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>CFA#w / fCFA#w</Table.Cell>
              <Table.Cell>
                Financial wealth for each of the seven
                <span
                  className="clickable"
                  onClick={() => {
                    props.handleChange("FinancialWealth");
                  }}
                >
                  Financial Wealth
                </span>
                segments for the context and the filter
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <p>Location:</p>
        <p>Count &amp; Countf</p>
        <p>Wealth &amp; Wealthf</p>
        <p>AGE</p>
        <p>INCOME</p>
        <p>WEALTH</p>
      </PageContent>
    </div>
  );
}
